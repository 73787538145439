/* @override 
	http://bergsskishop.test/css/min-bss-home.css
*/

@import url('http://assets.tuplo.test/css/tk-variables.css');
@import "bss-variables.css";
@import "slick.css";

/* @group Since 1955 */

#since1955 {
	text-align: center;
	margin: 60px auto 0;
	position: relative;
	width: 90%;
	max-width: 980px;
	padding-bottom: 20px;
}

#since1955 h2 {
	font-weight: 900;
	font-size: 2.0rem;
	text-transform: uppercase;
	background-color: white;
	display: inline-block;
	padding-left: 10px;
	padding-right: 10px;
}

#since1955 p {
	font-size: 0.9rem;
}

#since1955Line {
	border-top-style: double;
	border-top-width: 6px;
	position: absolute;
	top: 13px;
	z-index: -10;
	width: 100%;
	height: 21px;
}

/* @end */

/* @group Category Boxes */

#catBoxGallery {
	max-width: 1000px;
	margin: 20px auto 0;
	padding: 0 5px;
	display: flex;
	flex-wrap: wrap;
}

.catBox {
	display: block;
	position: relative;
	margin: .3%;
	width: 32.5%;
	height: 180px;
}

.catBox:before {
  background-color: rgba(255,255,255,0.2);
  width: 100%;
  height: 180px;
  top: 0;
  left: 0;
  position: absolute;
  z-index: 1;
  content: "";
}

.catBox:hover:before {
  background-color: rgba(255,255,255,0.0);
}

.catBoxFooter {
	background-color: rgba(0,0,0,0.5);
	color: white;
	padding: 10px 14px 20px 10px;
	position: absolute;
	width: 100%;
	font-size: 14px;
	font-weight: 300;
	line-height: 14px;
	z-index: 10;
	bottom: 0;
}

.catBoxFooter h3 {
	/*font: bold 34px/25px 'Roboto Condensed', sans-serif;*/
	font-weight: 900;
	font-size: 1.4rem;
	color: white;
	margin: 0;
}

/* @end */

/* @group Brand Slider */

#brandSlider {
	margin-left: auto;
	margin-right: auto;
	margin-top: 16px;
	width: 100%;
	/*padding-bottom: 20px;*/
	min-height: 80px;
}

.brandSliderLogo {
	display: none;
	opacity: 0.3;
	margin-left: auto;
	margin-right: auto;
}

/* @end */
/* @group Hero Slider */
#heroSlider {
	max-width: 2700px;
	margin-left: auto;
	margin-right: auto;
}

/* turn off - JS will turn on just before slick initializes */
#heroSlider .theSlide {
	display: none;
}

#heroSlider .theSlide:first-child {
	display: block;
}

.theSlide {
	text-align: center;
}

.theSlide a {
	display: block;
	height: 100%;
	text-decoration: none;
}

.heroSliderTitle {
	font-weight: 900;
	font-size: 35px;
	line-height: 30px;
	text-align: center;
	color: white;
	padding-top: 100px;
	padding-right: 20px;
	padding-left: 20px;
}

.heroSliderSubTitle {
	text-align: center;
	margin-top: 10px;
	color: white;
	padding-right: 20px;
	padding-left: 20px;
	font-size: 14px;
	line-height: 16px;
}

.heroSliderSubTitleBadge {
	text-align: center;
	color: white;
	background: rgba(0,0,0,0.5);
	display: inline-block;
	border: 1px solid white;
	padding: 6px 20px;
	margin-top: 20px;
	margin-right: 10px;
	margin-left: 10px;
	text-decoration: none;
}

#heroSliderNext, #heroSliderPrev {
	z-index: 11;
	position: absolute;
	background: rgba(255,255,255,0.7);
	border-style: none;
	width: calc(50% - 480px);
	height: 300px;
	color: white;
}

#heroSliderNext {
	text-align: left;
	right: 0;
}

.heroSliderPrevButton, .heroSliderNextButton {
	position: absolute;
	background-color: rgba(0,0,0,0.3);
	width: 40px;
	top: 120px;
	text-align: center;
	padding-top: 20px;
	padding-bottom: 20px;
}

.heroSliderPrevButton {
	right: -40px;
}

.heroSliderNextButton {
	left: -40px;
}

/* @end */

/* @group Featured Slider */

#featuredGallery {
	background: rgba(250,250,250,1);
	border: 1px solid #d8d8d8;
	border-right: none;
	border-left: none;
	margin-bottom: 30px;
}

#featuredSlider {
	position: relative;
	margin-top: 20px;
	max-width: 980px;
	margin: 20px auto 0px;
	text-align: center;
	min-height: 370px;
}

#featuredSlider h2 {
	margin-bottom: 20px;
	/*font: 800 24px 'Roboto Condensed', sans-serif;*/
	/*font-weight: 700;*/
	font-weight: 900;
	padding-bottom: 3px;
	padding-top: 4px;
	border-right-style: none;
	border-left-style: none;
}

#featuredSlider p {
	margin: 0 0 20px;
}

#featuredSliderPrevButton {
	position: absolute;
	top: 130px;
	left: 10px;
	z-index: 50;
}

#featuredSliderNextButton {
	top: 130px;
	right: 10px;
	position: absolute;
	z-index: 51;
}

#featuredSliderPrevButton,#featuredSliderNextButton {
	opacity: 0.5;
}

#featuredSliderPrevButton:hover,#featuredSliderNextButton:hover {
	opacity: 1;
}

#featuredItems {
	/*font-family: 'Roboto Condensed', sans-serif;*/
	font-size: 0.9rem;
	display: inline-block;
	width: 90%;
	line-height: 18px;
	margin-bottom: 70px;
}

.featuredItem {
	display: none;
	background: rgba(250,250,250,1);
}

.featuredItem img {
	margin-left: auto;
	margin-right: auto;
	margin-bottom: 20px;
	mix-blend-mode: multiply;
}

.featuredItem .priceText {
	color: var(--colorRed);
	font-weight: 700;
}

.featuredItem .priceFromText {
	color: var(--colorRed);
	font-size: 0.8rem;
	margin-right: 4px;
	font-weight: 500;
}

.featuredItem .price {
	color: black;
	font-size: 16px;
}

.featuredItem .brand {
	font-weight: bold;
	font-size: 18px;
}

.featuredItem div.title {
	margin-bottom: 10px;
}

.featuredItem a {
	text-decoration: none;
	color: #2e2e2e;
}



/* @end */

/* @group Featured Items 2 */
.featuredGallery2 {
	text-align: center;
	margin-top: 60px;
	margin-bottom: 60px;
}

.featuredGallery2 h2 {
	font-weight: 900;
	margin-bottom: 20px;
}

.featuredItems2 {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	position: relative;
	max-width: 980px;
	margin: 20px auto 0px;
}

.featuredItems2 .featuredItem2 a {
	text-decoration: none;
	color: var(--colorGrayDarkest);
}

.featuredItems2 .featuredItem2 {
	width: calc(20% - 10px);
	text-align: center;
	padding: 20px;
	margin: 5px;
	background: rgba(250,250,250,1);
}

.featuredItems2 .featuredItem2 img {
	padding: 10px;
	margin-bottom: 10px;
	width: 100%;
	height: auto;
	mix-blend-mode: multiply;
}

.featuredItems2 .featuredItem2 .brand {
	font-weight: 700;
	font-size: 0.95rem;
	line-height: 140%;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.featuredItems2 .featuredItem2 .title {
	font-size: 0.95rem;
	line-height: 140%;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	margin-bottom: 0;
}

.featuredItems2 .featuredItem2 .price {
	font-weight: 500;
	font-size: 0.9rem;
}

.featuredItems2 .featuredItem2 .priceText {
	color: var(--colorRed);
	font-weight: 700;
}

.featuredItems2 .featuredItem2 .priceFromText {
	font-size: 0.7rem;
	margin-right: 3px;
	color: var(--colorRed);
}

#featuredItemsGroup1 .featuredItem2:nth-child(16) {
	display: none;
}

/* @end */

/* @group Slick */

/*@charset 'UTF-8';*/
.slick-loading .slick-list {
    background: #fff url('/images/2015/ajax-loader.gif') center center no-repeat;
}

/* @group Arrows */

.slick-prev,
.slick-next
{
    font-size: 22px;
    opacity: 0.0;
    position: absolute;
    display: block;
    width: 22px;
    height: 60px;
    cursor: pointer;
    color: black;
    border: none;
    outline: none;
    background: transparent;
	top: 0;
	padding: 0;
	-webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus
{
    opacity: .5;
}

.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before
{
    opacity: .25;
}

.slick-prev
{
	left: 10px;
}
.slick-next
{
    right: 10px;
}
[dir='rtl'] .slick-prev
{
    right: 0;
    left: auto;
}
[dir='rtl'] .slick-next
{
    right: auto;
	left: 0;
}
.slick-prev:before,[dir='rtl'] .slick-next:before
{
    content: '←';
}
.slick-next:before,[dir='rtl'] .slick-prev:before
{
    content: '→';
}

/* @end */

/* @group Dots */

.slick-slider
{
	margin-bottom: 0;
}

.slick-dots {
    position: absolute;
    display: block;
    width: 100%;
    padding: 0;
    list-style: none;
    text-align: center;
	bottom: 0;
}

.slick-dots li {
    position: relative;
    display: inline-block;
    width: 20px;
    height: 20px;
    margin: 0 05px;
    padding: 0;
    cursor: pointer;
	list-style-type: decimal;
}
    
.slick-dots li button {
    font-size: 0;
    line-height: 0;
    display: block;
    width: 20px;
    height: 20px;
    padding: 5px;
    cursor: pointer;
    color: transparent;
    border: 0;
    outline: none;
    background: transparent;
}
.slick-dots li button:hover,
.slick-dots li button:focus
{
    outline: none;
}
.slick-dots li button:hover:before,
.slick-dots li button:focus:before
{
    opacity: 1;
}
.slick-dots li button:before
{
    position: absolute;
    top: 0;
    left: 0;
    width: 20px;
    height: 20px;
    content: '•';
    text-align: center;
    opacity: 0.6;
    color: white;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
	font: 30px/20px 'slick';
}

.slick-dots li.slick-active button:before
{
    opacity: 1.0;
    color: #fefefe;
}

#featuredSlider .slick-dots li button:before
{
    position: absolute;
    color: black;
     opacity: 0.2;
	top: 60px;
}

#featuredSlider .slick-dots li.slick-active button:before
{
    opacity: 0.5;
    color: #00000;
}

/* @end */
/* @end */

/* @group Media Queries */
@media only screen and (max-width: 850px) {
.catBox {
	width: 49%;
	margin: .5%;
}

.featuredItems2 .featuredItem2 {
	width: calc(25% - 10px);
}

#featuredItemsGroup1 .featuredItem2:nth-child(16) {
	display: block;
}

#featuredItemsGroup2 .featuredItem2:nth-child(16) {
	display: none;
}
}

@media only screen and (max-width: 767px) {
.featuredItems2 .featuredItem2 {
	width: calc(33.33% - 10px);	
}

#featuredItemsGroup1 .featuredItem2:nth-child(16) {
	display: none;
}
}

@media only screen and (max-width: 480px) {

#since1955 h2 {
	font-size: 39px;
}

.catBox {
	width: 86%;
	margin: .5% 7%;
}

.featuredItems2 .featuredItem2 {
	width: calc(45% - 10px);
}

#featuredItemsGroup1 .featuredItem2:nth-child(16) {
	display: block;
}

#heroSliderPrev,#heroSliderNext {
	display: none;
}

#brandSlider {
	min-height: 66px;
}

img.brandSliderLogo {
	height: auto;
	width: 80px;
}

}



/* @end */